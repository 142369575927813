<template>
  <div>
    authentication
  </div>
</template>

<script>
export default {
  name: "Authentication"
}
</script>
